import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';
import { chakra } from "@chakra-ui/react"
import './Devices.scss';

type DeviceProps = {
  children: ReactNode;
  className?: string;
  style?: CSSProperties
}

function Desktop_({ children, className, style }: DeviceProps) {
  const c = classNames({
    Desktop: true,
    Device: true,
    [className]: !!className
  })

  return (
    <div className={c} style={style}>
      {children}
    </div>
  )
}

export const Desktop = chakra(Desktop_);

function Mobile_ ({ children, className, style }: DeviceProps) {

  const c = classNames({
    Mobile: true,
    Device: true,
    [className]: !!className
  })

  return (
    <span className={c} style={style}>
      {children}
    </span>
  )
}

export const Mobile = chakra(Mobile_);
