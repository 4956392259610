import React from 'react';
import Helmet from 'react-helmet';
import './404.scss';
import './404.mobile.scss';
import { getPathInfo } from '../services/URL';
import Copy from '../components/Copy';
import ContentContainer from '../components/ContentContainer';
import Row, { RowItem } from '../components/Row';
import Link from '../components/Link';
import HeaderSection from '../components/HeaderSection';
import SearchTool from '../components/SearchPopup';
import { navigate } from 'gatsby-link';
import ErrorImage from '../assets/inline/error.svg'

class Error extends React.PureComponent {

  state = { key: 1 }

  componentDidMount() {
    this.setState({ key: 2 });
  }


  manualSearch = (term) => {
    navigate(`/search?searchTerm=${term}`);
  }

  renderList = (copy, nopadding) => {
    return (
      <ContentContainer className='lower-content' noPadding={nopadding}>
        <Row wrap >
          {
            copy.map((item) => {
              return (
                <RowItem width='33%' centerContent key={item.title}>
                  <div className='wrapper'>
                    <h4>{item.title}</h4>

                    {
                      item.links.map(link => {
                        return <Link to={link.to} key={link.to}>{link.title}</Link>
                      })
                    }
                  </div>

                </RowItem>
              )
            })
          }
        </Row>
      </ContentContainer>
    )
  }

  render() {

    const info = getPathInfo(this.props.location.pathname);

    let copy = this.props.copy[info.platform] || [];

    let defaultCopy = this.props.copy.default;

    return (
      <div className='error' key={this.state.key}>
        <Helmet>
          <title>404 | Apryse Documentation</title>
        </Helmet>

        <HeaderSection small className='header'>
          <Row>
            <RowItem centerContent>
              <ErrorImage className='error-img' />
              <h1>Page doesn't exist.</h1>

              <div className='search-wrap'>
                <SearchTool fullLength fullWidth disableAutoResults nav={false} onSubmit={this.manualSearch} clearOnSubmit={true} />
              </div>
            </RowItem>
          </Row>
        </HeaderSection>


        {
          /* 
            // keeping old copy incase footer returns
            {copy && copy.length > 0 && this.renderList(copy)}
           */
        }

        {/* {defaultCopy && defaultCopy.length > 0 && this.renderList(defaultCopy, copy.length > 0)} */}
      </div>
    );
  }

}

const errorPage = Copy(Error, require('../copy/404').default)

export default errorPage