import React from 'react';
import './HeaderSection.scss';
import './HeaderSection.mobile.scss';
import PropTypes from 'prop-types';
import ContentContainer from '../ContentContainer';
import classNames from 'classnames';
import Subheader from '../Subheader';
import PlatformList from '../PlatformList';
import { Mobile } from '../Devices';

/**
 * A wrapper component that creates a header at the top of the page
 */
export default class HeaderSection extends React.PureComponent {

  static propTypes = {
    /** An array of strings to render in the top bar. Used mainly for demos*/
    customBar: PropTypes.arrayOf(PropTypes.string),
    /** Additional classname to apply to the wrapper */
    className: PropTypes.string,
    /** The graphQL node of the latest blog post */
    latestBlogPost: PropTypes.any,
    /** Applies a background gradient to the wrapper */
    showGradients: PropTypes.bool,
    /** Makes the header smaller than the default */
    small: PropTypes.bool,
    /** Centers all text inside the wrapper */
    centerText: PropTypes.bool,
    /** An array of breadcrumbs to render in the top bar */
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape({ data: PropTypes.string, to: PropTypes.string })),
    /** Shows the platforms bar in the top right */
    platforms: PropTypes.bool,
    /** Centers everything, including divs */
    centerAll: PropTypes.bool,
    /** Makes the header extra small */
    extrasmall: PropTypes.bool,
    /** Applies a BG image to the header */
    bgImage: PropTypes.string,
    /** Elements to render inside the header */
    children: PropTypes.any
  }

  render() {

    const { customBar, className, showGradients, small, centerText, breadcrumbs, platforms, centerAll, extrasmall, bgImage } = this.props;

    const c = classNames({
      HeaderSection: true,
      small,
      extrasmall,
      centerText,
      // subheader: !!breadcrumbs,
      centerAll,
      platforms: platforms || !!customBar,
      [`${className}`]: !!className
    })

    const style = {};

    if (bgImage) {
      style.backgroundImage = `url('${bgImage}')`;
      style.backgroundSize = 'cover'
    }

    return (
      <div className={c} style={style}>
        {
          (breadcrumbs || platforms || customBar) &&
          <Subheader
            breadcrumbs={breadcrumbs}
            platforms={platforms}
            customBar={customBar}
          />
        }
        
        <ContentContainer header className='content'>
          {this.props.children}
        </ContentContainer>

        {
          platforms &&
          <div className='platform-container'>
            <Mobile>
              <PlatformList />
            </Mobile>
          </div>
          
        }

        {
          showGradients && 
          <React.Fragment>
            <div className='gradient' />
            <div className='pattern' />
          </React.Fragment>
        }
        
      </div>
    )
  }
}