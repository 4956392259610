import React from 'react';

import Link from '../Link';

import './Breadcrumb.scss'
import './Breadcrumb.mobile.scss'

export default function BreadCrumb ({ className = '', data }) {  
  return (
    <div className={`breadcrumb ${className}`}>
      {data.map(({ to, data }, i) => {
        return (
          <span key={i}>
            {i > 0 &&
              '/'
            }
            <Link className='link' to={to}>
              {data}
            </Link>
          </span>
        );
      })}
    </div>
  );
}  