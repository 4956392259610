import React from 'react';
import classNames from 'classnames';
import './Row.scss';
import './Row.mobile.scss';
import PropTypes from 'prop-types';
import StylePropType from 'react-style-proptype';

/**
 * 
 * A row component. Accepts a bunch of RowItem components as children, and lays them out in a row according to the screen size
 */
const Row = ({
  children,
  className,
  spaceBetween,
  spaceAround,
  nowrap,
  center,
  style = {},
  wrap,
  itemFill,
  evenMobile,
  alignLeft = false
}) => {

  const c = classNames({
    Row: true,
    spaceBetween,
    nowrap,
    wrap,
    center,
    evenMobile,
    spaceAround,
    alignLeft,
    [`${className}`]: !!className
  })

  let addSpacer = false;
  if (itemFill) {
    if (children.length % itemFill !== 0) {
      addSpacer = true;
    }

  }

  return (
    <div className={c} style={style}>
      {children}

      {
        addSpacer &&
        <div className='spacer' />
      }
    </div>
  )
}

Row.propTypes = {
  /** An array of RowItems */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  /** Additional className to apply to the Row */
  className: PropTypes.string,
  /** Adds 'space-between' style to the row */
  spaceBetween: PropTypes.bool,
  /** Prevents wrapping on mobile */
  nowrap: PropTypes.bool,
  /** Center all contents within the row */
  center: PropTypes.bool,
  /** Additional style to apply to the row */
  style: StylePropType,
  /** Apply flex-wrap to the Row */
  wrap: PropTypes.bool,
  /** Adds a spacer after all the items, if all the items do not fill up the div. This will help line up rows */
  itemFill: PropTypes.number,
  /** Forces 0 left and right margins on mobile, so that stacked elements line up */
  evenMobile: PropTypes.bool,
  /** Adds space-around to the items */
  spaceAround: PropTypes.bool,
}

const transferStyleProps = [
  'padding',
  'margin',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
  'paddingBottom',
  'marginLeft',
  'marginRight',
  'marginTop',
  'marginBottom'
]

/**
 * A Wrapper component that goes inside the Row component. Utility for alinging things in a row
 */
const RowItem = ({
  grow,
  children,
  noHeight,
  mobileAutoWidth,
  className,
  innerWidth,
  wrapperClass,
  margin,
  padding,
  centerContent,
  noMobileMargin,
  style,
  innerHeight,
  width,
  inlineFlex,
  onClick = () => undefined
}) => {
  const c = classNames({
    RowItem: true,
    noMobileMargin,
    centerContent,
    inlineFlex,
    mobileAutoWidth,
    [`${className}`]: !!className
  })

  const innerStyle = {
    width: innerWidth || '100%',
    height: innerHeight || 'unset',
  }

  if (margin) innerStyle.margin = margin;
  if (padding) innerStyle.padding = padding;

  if (style) {
    transferStyleProps.forEach(transfer => {
      if (style[transfer] !== undefined) {
        innerStyle[transfer] = style[transfer];
        delete style[transfer];
      }
    })
  }
  
  const wrapperStyle = {
    [(width || '').endsWith('px') ? 'maxWidth' : 'flexBasis']: width || '1',
    ...style
  };

  if (!grow && (width || '').endsWith('%')) {
    wrapperStyle.flexGrow = 0;
  }

  const cc = classNames({
    RowItemWrapper: true,
    centerContent,
    noHeight,
    [wrapperClass]: !!wrapperClass
  })

  return (
    <div className={c} style={wrapperStyle} onClick={onClick}>
      <div className={cc} style={innerStyle}>
        {children}
      </div>
    </div>
  )
}

RowItem.propTypes = {
  /** Forces the RowItem to flex-grow 1  */
  grow: PropTypes.bool,
  /** Items to render inside the rowitem */
  children: PropTypes.node,
  /** Forces height: auto */
  noHeight: PropTypes.bool,
  /** Unsets max width on mobile */
  mobileAutoWidth: PropTypes.bool,
  /** Additional classname to apply to the item */
  className: PropTypes.string,
  /** Width of the content - defaults to 100% */
  innerWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** className to apply to the wrapper div */
  wrapperClass: PropTypes.string,
  /** Margin of the container. Usually calculated from the getMargin function in styles/utils.js */
  margin: PropTypes.string,
  /** Padding of the container. Usually calculated from the getPadding function in styles/utils.js */
  padding: PropTypes.string,
  /** Centers all content inside the item */
  centerContent: PropTypes.bool,
  /** Removes the default bottom margin on mobile */
  noMobileMargin: PropTypes.bool,
  /** Additional style to apply to the item */
  style: StylePropType,
  /** Height of the inner container - defaults to 100% */
  innerHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Width of the item. Defaults to flex-grow: 1 */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Applies inline-flex style to the item */
  inlineFlex: PropTypes.bool,
  /** On click handler for the item */
  onClick: PropTypes.func,
}

/**
 * An empty container with flex-grow: 1 applied. Will take up any empty space it can
 */
export const RowSpacer = () => <div className='row-spacer' />;

export default Row;
export {
  RowItem
}