import { Box, chakra, Icon, Spinner } from '@chakra-ui/react';
import React, { Suspense, SVGAttributes } from 'react';

const AndroidIcon = React.lazy(() => import('./platforms/android'));
const IOSIcon = React.lazy(() => import('./platforms/ios'));
const DotNetCoreIcon = React.lazy(() => import('./platforms/dotnetcore'));
const NodeIcon = React.lazy(() => import('./platforms/node'));
const DotNetIcon = React.lazy(() => import('./platforms/dotnet'));
const WindowsIcon = React.lazy(() => import('./platforms/windows'));
const AppleIcon = React.lazy(() => import('./platforms/apple'));
const JavaIcon = React.lazy(() => import('./platforms/java'));
const CPPIcon = React.lazy(() => import('./platforms/cpp'));
const PHPIcon = React.lazy(() => import('./platforms/php'));
const PythonIcon = React.lazy(() => import('./platforms/python'));
const RubyIcon = React.lazy(() => import('./platforms/ruby'));
const CoreIcon = React.lazy(() => import('./platforms/core'));
const FrameworksIcon = React.lazy(() => import('./platforms/frameworks'));
const LinuxIcon = React.lazy(() => import('./platforms/linux'));
const ServerIcon = React.lazy(() => import('./platforms/server'));
const WebIcon = React.lazy(() => import('./platforms/web'));
const XamarinIcon = React.lazy(() => import('./platforms/xamarin'));
const GoIcon = React.lazy(() => import('./platforms/go'));
const SalesforceIcon = React.lazy(() => import('./platforms/salesforce'));
const ReactIcon = React.lazy(() => import('./platforms/react'));
const AngularIcon = React.lazy(() => import('./platforms/angular'));
const VueIcon = React.lazy(() => import('./platforms/vue'));
const JSIcon = React.lazy(() => import('./platforms/js'));
const TSIcon = React.lazy(() => import('./platforms/typescript'));
const SwiftIcon = React.lazy(() => import('./platforms/swift'));
const MobileIcon = React.lazy(() => import('./platforms/mobile'));
const DesktopIcon = React.lazy(() => import('./platforms/desktop'));
const AppianIcon = React.lazy(() => import('./platforms/appian'));
const ElectronIcon = React.lazy(() => import('./platforms/electron'));
const CatalystIcon = React.lazy(() => import('./platforms/catalyst'));
const WebviewerIcon = React.lazy(() => import('./platforms/webviewer'));
const SvelteIcon = React.lazy(() => import('./platforms/svelte'));
const SharepointIcon = React.lazy(() => import('./platforms/sharepoint'));
const MacOSIcon = React.lazy(() => import('./platforms/macos'));
const BlazorIcon = React.lazy(() => import('./platforms/blazor'));
const ServerSdkIcon = React.lazy(() => import('./platforms/server-sdk'));
const ServiceNowIcon = React.lazy(() => import('./platforms/servicenow'));
const FlutterIcon = React.lazy(() => import('./platforms/flutter'));
const MendixIcon = React.lazy(() => import('./platforms/mendix'));
const OutSystemsIcon = React.lazy(() => import('./platforms/outsystems'));
const WvServerIcon = React.lazy(() => import('./platforms/wv-server'));
const CliIcon = React.lazy(() => import('./platforms/cli'));
const VbIcon = React.lazy(() => import('./platforms/vb'));
const CSharpIcon = React.lazy(() => import('./platforms/csharp'));
const KotlinIcon = React.lazy(() => import('./platforms/kotlin'));

const WebColored = React.lazy(() => import('./platforms/colored/web'));
const AndroidColored = React.lazy(() => import('./platforms/colored/android'));
const IOSColored = React.lazy(() => import('./platforms/colored/ios'));
const LinuxColored = React.lazy(() => import('./platforms/colored/linux'));
const MacOSColored = React.lazy(() => import('./platforms/colored/macos'));
const WindowsColored = React.lazy(() => import('./platforms/colored/windows'));
const NodeJSColoredIcon = React.lazy(() => import('./platforms/colored/node'));
const XamarinColoredIcon = React.lazy(
  () => import('./platforms/colored/xamarin')
);
const JavaColoredIcon = React.lazy(() => import('./platforms/colored/java'));
const DotNetColoredIcon = React.lazy(
  () => import('./platforms/colored/dot-net')
);
const GoColoredIcon = React.lazy(() => import('./platforms/colored/go'));
const CPPColoredIcon = React.lazy(() => import('./platforms/colored/cpp'));
const DotNetCoreColoredIcon = React.lazy(
  () => import('./platforms/colored/dot-net-core')
);
const CSharpColoredIcon = React.lazy(
  () => import('./platforms/colored/csharp')
);
const JSColoredIcon = React.lazy(() => import('./platforms/colored/js'));
const PythonColoredIcon = React.lazy(
  () => import('./platforms/colored/python')
);
const VBColoredIcon = React.lazy(() => import('./platforms/colored/vb'));
const RubyColoredIcon = React.lazy(() => import('./platforms/colored/ruby'));
const ReactColoredIcon = React.lazy(() => import('./platforms/colored/react'));
const AngularColoredIcon = React.lazy(
  () => import('./platforms/colored/angular')
);
const TSColoredIcon = React.lazy(() => import('./platforms/colored/ts'));
const BlazorColoredIcon = React.lazy(
  () => import('./platforms/colored/blazor')
);
const ElectronColoredIcon = React.lazy(
  () => import('./platforms/colored/electron')
);
const MendixColoredIcon = React.lazy(
  () => import('./platforms/colored/mendix')
);
const SharepointColoredIcon = React.lazy(
  () => import('./platforms/colored/sharepoint')
);
const VueColoredIcon = React.lazy(() => import('./platforms/colored/vue'));
const SvelteColoredIcon = React.lazy(
  () => import('./platforms/colored/svelte')
);
const CatalystColoredIcon = React.lazy(
  () => import('./platforms/colored/catalyst')
);
const FlutterColoredIcon = React.lazy(
  () => import('./platforms/colored/flutter')
);
const SwiftColoredIcon = React.lazy(() => import('./platforms/colored/swift'));
const AppianColoredIcon = React.lazy(
  () => import('./platforms/colored/appian')
);

// https://stackoverflow.com/questions/57065617/how-to-check-if-string-in-type/57065680#57065680

export function isOfTypePlatform(platform: string): platform is Platforms {
  return (platforms as Set<string>).has(platform);
}
const platforms = new Set([
  // pre-2019 android logo
  'android',
  'ios',
  'uwp',
  'apple',
  'cli',
  'core',
  'frameworks',
  'ios',
  'linux',
  'mac',
  'macos',
  'server-desktop',
  'web',
  'windows',
  'xamarin',
  'dotnet',
  'dotnetcore',
  'nodejs',
  'java',
  'cpp',
  'php',
  'python',
  'ruby',
  'go',
  'salesforce',
  'react',
  'angular',
  'vue',
  'js',
  'ts',
  'swift',
  'mobile',
  'desktop',
  'csharp',
  'vb',
  'mendix',
  'sharepoint',
  'appian',
  'outsystems',
  'servicenow',
  'flutter',
  'electron',
  'catalyst',
  'serverless',
  'wv-server',
  'server-sdk',
  'webviewer',
  'svelte',
  'blazor',
  'cli',
  'kotlin'
] as const);

export type Platforms = typeof platforms extends Set<infer T> ? T : never;

export type PlatformIconProps = {
  platform: Platforms;
  /**
   * Renders the colored version of the icon. If set, the 'color' prop is ignored
   */
  colored?: boolean;
  className?: string;
} & Pick<SVGAttributes<HTMLOrSVGElement>, 'xmlns' | 'viewBox'>;

export type InnerIconProps = {
  color?: string;
};

const PlatformIcon = ({
  platform,
  colored,
  className,
  ...rest
}: PlatformIconProps) => {
  // note: need box with display flex align center style so that spinner is hidden correctly

  const Fallback = (
    <Box
      className={className}
      display='flex'
      alignItems='center'
      w='20px'
      h='20px'
    >
      <Spinner color='gray.300' />
    </Box>
  );

  if (typeof window === 'undefined') return Fallback;

  return (
    <Box display='flex' alignItems='center'>
      <Icon
        viewBox={`0 0 20 20`}
        width={'20px'}
        height={'20px'}
        className={className}
        fill='gray.600'
        {...rest}
      >
        {getInner(platform, Fallback, colored)}
      </Icon>
    </Box>
  );
};

const getInner = (platform, Fallback, colored) => {
  switch (platform) {
    case 'android':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <AndroidColored />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <AndroidIcon />
        </Suspense>
      );
    case 'ios':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <IOSColored />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <IOSIcon />
        </Suspense>
      );
    case 'dotnetcore':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <DotNetCoreColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <DotNetCoreIcon />
        </Suspense>
      );
    case 'nodejs':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <NodeJSColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <NodeIcon />
        </Suspense>
      );
    case 'kotlin':
      return (
        <Suspense fallback={Fallback}>
          <KotlinIcon />
        </Suspense>
      );
    case 'dotnet':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <DotNetColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <DotNetIcon />
        </Suspense>
      );
    case 'uwp':
    case 'windows':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <WindowsColored />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <WindowsIcon />
        </Suspense>
      );
    case 'mac':
    case 'apple':
      return (
        <Suspense fallback={Fallback}>
          <AppleIcon />
        </Suspense>
      );
    case 'java':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <JavaColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <JavaIcon />
        </Suspense>
      );
    case 'cpp':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <CPPColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <CPPIcon />
        </Suspense>
      );
    case 'php':
      return (
        <Suspense fallback={Fallback}>
          <PHPIcon />
        </Suspense>
      );
    case 'python':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <PythonColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <PythonIcon />
        </Suspense>
      );
    case 'ruby':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <RubyColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <RubyIcon />
        </Suspense>
      );
    case 'cli':
      return (
        <Suspense fallback={Fallback}>
          <CliIcon />
        </Suspense>
      );
    case 'core':
      return (
        <Suspense fallback={Fallback}>
          <CoreIcon />
        </Suspense>
      );
    case 'frameworks':
      return (
        <Suspense fallback={Fallback}>
          <FrameworksIcon />
        </Suspense>
      );
    case 'linux':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <LinuxColored />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <LinuxIcon />
        </Suspense>
      );
    case 'macos':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <MacOSColored />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <MacOSIcon />
        </Suspense>
      );
    case 'server-desktop':
      return (
        <Suspense fallback={Fallback}>
          <ServerIcon />
        </Suspense>
      );
    case 'web':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <WebColored />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <WebIcon />
        </Suspense>
      );
    default:
      return null;
    case 'xamarin':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <XamarinColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <XamarinIcon />
        </Suspense>
      );
    case 'go':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <GoColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <GoIcon />
        </Suspense>
      );
    case 'salesforce':
      return (
        <Suspense fallback={Fallback}>
          <SalesforceIcon />
        </Suspense>
      );
    case 'react':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <ReactColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <ReactIcon />
        </Suspense>
      );
    case 'angular':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <AngularColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <AngularIcon />
        </Suspense>
      );
    case 'vue':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <VueColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <VueIcon />
        </Suspense>
      );
    case 'js':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <JSColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <JSIcon />
        </Suspense>
      );
    case 'ts':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <TSColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <TSIcon />
        </Suspense>
      );
    case 'swift':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <SwiftColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <SwiftIcon />
        </Suspense>
      );
    case 'mobile':
      return (
        <Suspense fallback={Fallback}>
          <MobileIcon />
        </Suspense>
      );
    case 'desktop':
      return (
        <Suspense fallback={Fallback}>
          <DesktopIcon />
        </Suspense>
      );
    case 'csharp':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <CSharpColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <CSharpIcon />
        </Suspense>
      );
    case 'vb':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <VBColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <VbIcon />
        </Suspense>
      );
    case 'mendix':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <MendixColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <MendixIcon />
        </Suspense>
      );
    case 'sharepoint':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <SharepointColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <SharepointIcon />
        </Suspense>
      );
    case 'appian':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <AppianColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <AppianIcon />
        </Suspense>
      );
    case 'outsystems':
      return (
        <Suspense fallback={Fallback}>
          <OutSystemsIcon />
        </Suspense>
      );
    case 'servicenow':
      return (
        <Suspense fallback={Fallback}>
          <ServiceNowIcon />
        </Suspense>
      );

    case 'flutter':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <FlutterColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <FlutterIcon />
        </Suspense>
      );
    case 'electron':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <ElectronColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <ElectronIcon />
        </Suspense>
      );
    case 'catalyst':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <CatalystColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <CatalystIcon />
        </Suspense>
      );
    case 'serverless':
      return (
        <Suspense fallback={Fallback}>
          <ServerSdkIcon />
        </Suspense>
      );
    case 'wv-server':
      return (
        <Suspense fallback={Fallback}>
          <WvServerIcon />
        </Suspense>
      );
    case 'server-sdk':
      return (
        <Suspense fallback={Fallback}>
          <ServerSdkIcon />
        </Suspense>
      );
    case 'webviewer':
      return (
        <Suspense fallback={Fallback}>
          <WebviewerIcon />
        </Suspense>
      );
    case 'svelte':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <SvelteColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <SvelteIcon />
        </Suspense>
      );
    case 'blazor':
      if (colored) {
        return (
          <Suspense fallback={Fallback}>
            <BlazorColoredIcon />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={Fallback}>
          <BlazorIcon />
        </Suspense>
      );
  }
};

export default chakra(PlatformIcon);
