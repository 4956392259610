import React from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import Breadcrumbs from '../Breadcrumb';
import ContentContainer from '../ContentContainer';
import PlatformList from '../PlatformList';
import { Desktop } from '../Devices';

import './Subheader.scss';
import './Subheader.mobile.scss';


export default class Subheader extends React.PureComponent {

  static propTypes = {
    breadcrumbs: PropTypes.array,
    platforms: PropTypes.bool
  }

  render() {

    const { breadcrumbs, platforms, customBar } = this.props;

    const c = ClassNames({
      Subheader: true,
      customBar: !!customBar
    })

    return (
      <div className={c}>
        <ContentContainer noPadding>
          {
            breadcrumbs &&
            <Breadcrumbs
              data={breadcrumbs}
            />
          }

          {
            platforms &&
            <Desktop><PlatformList /></Desktop>
          }

          {
            customBar &&
            customBar.map((p, idx) => { 
              return <span key={idx}>{p}</span>;
            })
          }
          
        </ContentContainer>
        
      </div>
    )
  }

}