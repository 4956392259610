import React from 'react';

/**
 * Not really a component, but injects a component with a `copy` prop
 * @param {any} WrappedComponent The component to pass the copy too
 * @param {function} copyFunc A function that returns the copy object
 * @returns {any} Returns the injected react component
 */
export default (WrappedComponent, copyFunc) => {

  class CopyHOC extends React.PureComponent {

    render() {
      const { forwardedRef } = this.props;
      const copy = copyFunc(null);

      return <WrappedComponent ref={forwardedRef} copy={copy} { ...this.props } />
    }

  }

  return React.forwardRef((props, ref) => {
    return <CopyHOC {...props} forwardedRef={ref} />;
  })

}