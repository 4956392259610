import React from 'react';

import Link from '../Link';

import PlatformList from '../../constants/static-text/platform-list.json';
import PlatformIcon from '../../icons/PlatformIcon';
import './PlatformList.scss';
import './PlatformList.mobile.scss';
import { Box } from '@chakra-ui/layout';

export default function PlatformListComponent ({ location, className = '' }) {

  if (!location) location = typeof window === 'undefined' ? {} : window.location

  return (
    <div className={`platform-list ${className}`}>
      {/*<p>Apryse supports:</p>*/}
      {PlatformList.data.map(({ image, to }, i) => (
        <Box key={i} display='inline'>
          <Link className={`link ${location.pathname === to ? 'white' : ''}`}to={to}>
            <PlatformIcon platform={image} fill='white'/>
          </Link>
        </Box>
      ))}
    </div>
  );
}